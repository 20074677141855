@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  font-family: 'Noto Sans TC', sans-serif !important;
  font-weight: 400 !important;
}

article {
  padding: 20%;
  font-weight: bold;
  font-size: larger;
}

#loadingNews {
  display: flex;
  margin-top: 20%;
  margin-left: 45%;
  margin-bottom: 20%;
}

#lastUpdate {
  font-size: xx-small;
}

#podcastWidget, #newsResult, #statusArea, #menuItemLiveVote, #menuItemPodcast {
  margin-left: 5%;
  margin-right: 5%;
}

.avatar {
  border-radius: 50%;
  width: 150px;
}

#table-aboutUs {
  display: block;  
  margin-left: 5%;
}

#table-aboutUs th{
  padding: 15px;
  vertical-align: bottom;
}

#aboutUs-title {
  margin-left: 5%;
  font-weight: bold;
  text-decoration: underline;
}

#aboutUs-description {
  margin-left: 5%;
  margin-right: 5%;
  text-align: justify;
}

.News-headline {
  text-align: left;
  width: 85%;
  margin: 5%;
}

#gridNews {
  display: block;
  width: 100%;
  border: 0px;
  overflow: auto;
  zoom: 35%;
}

#voteMedia {
  margin-right: 30%;
}

.newsImage {
  width: 345px;
}

.selectMedia {
  width: 100%;
  margin-bottom: 20px;
}

.text-wrap { 
  white-space: normal; 
}

.audioSpeed {
  margin-bottom: 10px;
}

.speedControl {
  margin-top: 30px;
}

#voteResult {
  font-style: italic;
  line-height: 25px;
}

#attribution {
  font-size: xx-small;
  color: whitesmoke;
  text-decoration: underline;
}

footer {
  text-align: center;
}

Footer p {
  font-size: small;
 }

ul {
  cursor: pointer;
}

button {
  margin-left: 5px;
  margin-right: 5px;
  transition-duration: 0.4s;
  border-width: thin;
  font-family: 'Noto Sans TC', sans-serif !important;
  font-weight: 400 !important;
}

button:disabled {
  color: #c0c0c0;
  cursor: not-allowed;
} 

img {
  height: auto;
  max-width: none; 
}

a {
  color: black;
  text-decoration: none;
}

a:Hover {
  color: navy;
}

.BackToTop {
  cursor: pointer;
  width: 30px;
  z-index: 3000;
  bottom: 10px;
  right: 1%;
  position: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTypography-root {
  flex-grow: 1;
}

 .newsImage {
  border-radius: 10px 25px; 
  max-width: 98%;
  height: auto;
} 

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.ReactTable {
  border-style: none !important;
}

.rt-tr-group {
  display: block;
  padding: 0.5px;
  border: 1px solid #c0c0c0 !important;
  border-bottom: solid 1px #c0c0c0 !important;
  border-radius: 0.5em;

  margin-top: 15px;
  text-align: center;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 1px 0 0 #c0c0c0 !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -1px 0 0 #c0c0c0 !important;
}

.greenButton:enabled {
  color: #32cd32 !important;
  border: 1px solid #32cd32 !important;
}
