.ReactTable {
  border-style: none !important;
}

.rt-tr-group {
  display: block;
  padding: 0.5px;
  border: 1px solid #c0c0c0 !important;
  border-bottom: solid 1px #c0c0c0 !important;
  border-radius: 0.5em;

  margin-top: 15px;
  text-align: center;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 1px 0 0 #c0c0c0 !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -1px 0 0 #c0c0c0 !important;
}

.greenButton:enabled {
  color: #32cd32 !important;
  border: 1px solid #32cd32 !important;
}